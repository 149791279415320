@import '../../../../defaults';

@include media-breakpoint-down(sm) {
  .swiper-wrapper {
    padding-left: 50px;

    > li:first-child {
      margin-left: -30px;
    }

    > li:last-child {
      margin-right: 50px;
    }
  }
}

.tab-content {
  line-height: $line-height-lg;

  .anw-two-columns {
    column-count: 1;

    @include media-breakpoint-up(sm) {
      column-count: 2;
    }
  }

  .anw-three-columns {
    column-count: 1;

    @include media-breakpoint-up(sm) {
      column-count: 2;
    }

    @include media-breakpoint-up(md) {
      column-count: 3;
    }
  }

  .anw-four-columns {
    column-count: 1;

    @include media-breakpoint-up(sm) {
      column-count: 2;
    }

    @include media-breakpoint-up(md) {
      column-count: 4;
    }
  }

  .anw-six-columns {
    column-count: 1;

    @include media-breakpoint-up(sm) {
      column-count: 2;
    }

    @include media-breakpoint-up(md) {
      column-count: 4;
    }

    @include media-breakpoint-up(xxl) {
      column-count: 6;
    }
  }
}
